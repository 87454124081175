import { Button, Flex, Typography } from "antd"
import React, { FC, useState } from "react"

import style from "./style.m.scss"
import { VacationsSettings } from "./VacationsLocations"
import { LeaveTypes } from "./LeaveTypes"

const VacationsSettingsPaging: FC = () => {
  const [page, setPage] = useState<"locations" | "leaveTypes">("locations")

  const handleChangePage = (pageName: "locations" | "leaveTypes") => {
    setPage(pageName)
  }

  return (
    <>
      <Flex>
        <Typography.Title datatest-id="dept-name" level={3} className={style.header}>
          Vacations settings
        </Typography.Title>
      </Flex>
      <Flex className={style.buttonsContainer}>
        <Button
          type="link"
          onClick={() => {
            handleChangePage("locations")
          }}
        >
          Locations
        </Button>
        <Button
          type="link"
          onClick={() => {
            handleChangePage("leaveTypes")
          }}
        >
          Leave types
        </Button>
      </Flex>
      {page === "locations" ? <VacationsSettings /> : <LeaveTypes />}
    </>
  )
}

export default VacationsSettingsPaging
