import backendApi from "../../backendApi"

import { fetchVacationLogs } from "./GET/fetchVacationLogs"
import { fetchVacations } from "./GET/fetchVacations"
import { createVacation } from "./POST/createVacation"
import { cancelVacation } from "./PUT/cancelVacation"
import { resolveVacation } from "./PATCH/resolveVacation"
import { fetchVacationOptions } from "./GET/fetchVacationOptions"

export const vacationsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchVacations: fetchVacations(build),
    createVacation: createVacation(build),
    cancelVacation: cancelVacation(build),
    resolveVacation: resolveVacation(build),
    fetchVacationOptions: fetchVacationOptions(build),
    fetchVacationLogs: fetchVacationLogs(build)
  })
})
