import { DepartmentsFiltersForQuery } from "@interfaces*"
import { GetDepartmentsOptionsParams } from "@state/types/departments"

export const DEPARTMENTS_URL = `/departments`

export const DEPARTMENT_OPTIONS_URL = `${DEPARTMENTS_URL}/options`

export const generateDepartmentByIdURL = (departmentId: string | number) => `${DEPARTMENTS_URL}/${departmentId}`

export const generateDepartmentURL = (departmentId: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("fields[users]", "id,first_name,last_name,second_name,products")
  searchParams.set("include[user]", "first_name,second_name,last_name")
  searchParams.set("include", "positions,chief_position.user,positions.user")
  searchParams.set(
    "extra_fields[departments]",
    "parent,occupied_members_ids,subordinates,available_parents,subordinate_chiefs,is_activatable,is_closable" // ,head_products,owned_products,first_department_with_occupied_head_position
  )

  return `${DEPARTMENTS_URL}/${departmentId}${separator}${searchParams.toString()}`
}

export const generateDepartmentsURL = (page: number, size: number, filters?: DepartmentsFiltersForQuery) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("fields[users]", "id,first_name,last_name,second_name")
  searchParams.set("include", "chief_position.user,positions")
  searchParams.set(
    "extra_fields[departments]",
    "parent,occupied_positions_count,positions_count,subordinates_count,is_activatable,is_closable"
  )
  searchParams.set("page[size]", String(size))
  searchParams.set("page[number]", String(page))

  if (filters) {
    if (filters.id) {
      searchParams.set("filter[id]", filters.id)
      return `${DEPARTMENTS_URL}${separator}${searchParams.toString()}`
    }

    Object.entries(filters).forEach(filter => {
      if (filter[1]) searchParams.set(`filter[${filter[0]}]`, String(filter[1]))
    })
  }

  return `${DEPARTMENTS_URL}${separator}${searchParams.toString()}`
}

export const generateDepartmentsLogsURL = (departmentId: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("fields[users]", "email")
  searchParams.set("include", "user")

  return `${DEPARTMENTS_URL}/${departmentId}/logs${separator}${searchParams.toString()}`
}

export const generateDepartmentProductsURL = (id: string) => `${DEPARTMENTS_URL}/${id}/products`

export const generateDepartmentsOptionsURL = (params: GetDepartmentsOptionsParams) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  if (params.withPositions) {
    searchParams.set("with_positions", "true")
  }

  if (params.exclude) {
    searchParams.set("exclude", params.exclude.join(","))
  }

  return `${DEPARTMENT_OPTIONS_URL}${separator}${searchParams.toString()}`
}
