import { DepartmentStatus } from "@enums"
import { DepartmentsFilters } from "@interfaces*"
import { departmentsApi } from "@state/services/subApis"

import { useListViewState } from ".."

const initialDepartmentsFiltersValue: DepartmentsFilters = {
  deptId: null,
  status: DepartmentStatus.Active,
  headOfDeptId: null,
  parentDeptId: null
}

const transformDeptStatus = (status: DepartmentStatus) => {
  switch (status) {
    case DepartmentStatus.Active:
      return String(true)
    case DepartmentStatus.Inactive:
      return String(false)
    default:
      return null
  }
}

const transformFilters = (filters: DepartmentsFilters) => {
  return {
    id: filters.deptId,
    active: transformDeptStatus(filters.status),
    chief_user_id: filters.headOfDeptId,
    parent_id: filters.parentDeptId
  }
}

export const useDepartmentsViewState = () => {
  const { filters, paging, onFiltersChange, onPagingChange } = useListViewState(initialDepartmentsFiltersValue)
  const { page, size } = paging

  const { departments, totalCount, isLoading, isFetching } = departmentsApi.endpoints.fetchDepartments.useQuery(
    { page, size, filters: transformFilters(filters) },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        departments: data?.entities || [],
        totalCount: data?.totalCount || 0,
        isLoading,
        isFetching
      })
    }
  )

  return {
    page,
    size,
    filters,
    onFiltersChange,
    onPagingChange,
    departments,
    isLoading,
    isFetching,
    totalDepartmentsCount: totalCount,
    initialDepartmentsFiltersValue
  }
}
