const clearDuplicateSpaces = (text: string): string => text.replace(/\s+/g, " ").trim()

const DEFAULT_NAME_LENGTH = 25

export const generateFullName = (lastName = "", firstName = "", patronymic = ""): string =>
  clearDuplicateSpaces(`${lastName} ${firstName} ${patronymic}`)

export const generateShortName = (lastName = "", firstName = ""): string => {
  const initialFirstName = `${firstName.charAt(0)}.`
  return clearDuplicateSpaces(`${lastName} ${initialFirstName}`)
}

export const capitalizeFirstLetter = (str?: string) => {
  if (typeof str !== "string") {
    return undefined
  }

  if (str.trim() === "") {
    return str
  }

  return str[0].toUpperCase() + str.slice(1)
}

export const formatLongNames = (name: string, maxSymbols: number = DEFAULT_NAME_LENGTH) => {
  if (name.length < maxSymbols) return name
  return `${name.slice(0, maxSymbols)}...`
}
