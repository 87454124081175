import React, { FC, useState } from "react"
import { Button, Tooltip } from "antd"
import { InfoCircleOutlined, ToolOutlined } from "@ant-design/icons"
import { VacationQuota } from "@interfaces*"

import { QuotaFormItem } from "../../components/QuotaFormItem"
import { MIN_QUOTA } from "../../helpers/constants"

type Props = {
  quota: VacationQuota
  handleFieldsChange: () => void
}

export const QUOTA_INPUT_SEPARATOR = ":"
export const QUOTA_INPUT_ID_POSITION = 1
export const QUOTA_INPUT_CATEGORY_POSITION = 2

const QuotaInput: FC<Props> = ({ quota, handleFieldsChange }) => {
  const {
    id,
    attributes: { days, calculable, category_id, permanent, category }
  } = quota
  const [isUnlimited, setIsUnlimited] = useState(calculable ? false : days === null)
  const [isDisabled, setIsDisabled] = useState(days === 0)

  const handleClickIcon = () => {
    if (isDisabled) setIsDisabled(false)
    calculable ? setIsUnlimited(false) : setIsUnlimited(!isUnlimited)
    handleFieldsChange()
  }

  const handleClickDisabled = () => {
    if (isUnlimited) setIsUnlimited(false)
    isDisabled ? setIsDisabled(false) : setIsDisabled(!isDisabled)
    handleFieldsChange()
  }

  if (isDisabled)
    return (
      <QuotaFormItem
        name={["qid", id, String(category_id), "db"]}
        label={category}
        placeholder="Disabled"
        disabled={isDisabled}
        permanent={permanent}
        calculable={calculable}
        handleDisabled={handleClickDisabled}
        handleClickIcon={handleClickIcon}
      />
    )

  if (isUnlimited)
    return (
      <QuotaFormItem
        name={["qid", id, String(category_id), "un"]}
        label={category}
        initialValue={null}
        placeholder="Unlimited"
        disabled={isUnlimited}
        permanent={permanent}
        calculable={calculable}
        handleDisabled={handleClickDisabled}
        handleClickIcon={handleClickIcon}
      />
    )

  return (
    <QuotaFormItem
      name={["qid", id, String(category_id), "ex"]}
      label={category}
      initialValue={days || MIN_QUOTA}
      placeholder="Num"
      disabled={false}
      permanent={permanent}
      calculable={calculable}
      handleDisabled={handleClickDisabled}
      handleClickIcon={handleClickIcon}
      addon={
        <>
          {calculable ? (
            <Tooltip title="Must be a value">
              <InfoCircleOutlined style={{ padding: "0 5px" }} />
            </Tooltip>
          ) : (
            <Button shape="circle" size="small" type="text" onClick={handleClickIcon}>
              <ToolOutlined />
            </Button>
          )}
        </>
      }
    />
  )
}

export default QuotaInput
