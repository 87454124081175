import { useAlert } from "@hooks"
import { VacationSettings, SaveVacationError } from "@interfaces"
import { vacationsSettingsApi } from "@state/services/subApis"
import dayjs from "dayjs"

export const useSettingsEdit = () => {
  const { success, error } = useAlert()

  const [settingsEdit] = vacationsSettingsApi.endpoints.changeVacationSettings.useMutation()

  return (settings: VacationSettings, newSettings: { [n: string]: any }) =>
    settingsEdit({
      data: {
        id: settings.id,
        type: "vacation_settings",
        attributes: {
          expiration: newSettings?.expiration ? dayjs(newSettings?.expiration_date).format("YYYY-MM-DD") : null,
          leave_type: newSettings?.leave_type ? newSettings.leave_type : settings.attributes.leave_type,
          workdays: newSettings?.workdays ? newSettings.workdays : settings.attributes.workdays,
          tooltip: newSettings?.tooltip ? newSettings.tooltip : settings.attributes.tooltip,
          planning_start_date: newSettings.planning_dates.planning_start_date,
          planning_end_date: newSettings.planning_dates.planning_end_date
        }
      }
    }).then(res => {
      if ("data" in res) success("Settings changed successfully")
      if ("error" in res) {
        const { error: resError } = res as SaveVacationError
        error(resError.data.errors.map(error => error.detail).join("\n"))
      }
    })
}
