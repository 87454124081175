import { VacationSettingResponse } from "@state/types/vacations"
import { Build } from "@state/services/types"

import { VACATIONS_SETTINGS_URL } from "../urls"

export const fetchVacationSettings = (build: Build) =>
  build.query<VacationSettingResponse, null>({
    query: () => ({
      url: VACATIONS_SETTINGS_URL,
      params: { include: ["location", "vacation_quota", "assignment_hrs"] }
    }),
    providesTags: result =>
      result && result.data.length
        ? [...result.data.map(setting => ({ type: "VacationSettings", id: setting.id })), "LeaveType"]
        : ["VacationSettings", "LeaveType"]
  })
