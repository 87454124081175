import backendApi from "@state/services/backendApi"

import { searchSlackUsers } from "./GET/searchSlackUsers"
import { fetchSlackUsersOptions } from "./GET/fetchSlackUsersOptions"
import { updateSlackUser } from "./PUT/updateSlackUser"

export const slackUsersApi = backendApi.injectEndpoints({
  endpoints: build => ({
    searchSlackUsers: searchSlackUsers(build),
    fetchSlackUsersOptions: fetchSlackUsersOptions(build),
    updateSlackUser: updateSlackUser(build)
  })
})
