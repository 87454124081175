import { usersApi } from "@state/services/subApis"
import React, { FC, useState } from "react"
import { Collapse, Typography } from "antd"
import { Link } from "react-router-dom"
import { generateUserProfileRoute } from "@routes/inboundRoutes"
import dayjs from "dayjs"
import cn from "classnames"
import global from "@styles/global.m.scss"

import style from "./style.m.scss"

type LogsProps = {
  userId: string
}

export const Logs: FC<LogsProps> = ({ userId }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { logs } = usersApi.endpoints.fetchUserLogs.useQuery(userId, {
    selectFromResult: ({ data, isFetching }) => ({
      logs: data || [],
      isFetching
    }),
    skip: !isExpanded
  })

  const handleCollapse = () => {
    setIsExpanded(!isExpanded)
  }

  const logsItems = [
    {
      label: (
        <Typography.Title level={4} className={style.header}>
          History
        </Typography.Title>
      ),
      children: logs.map(log => (
        <p key={log.id}>
          <span>{`${log.record} by `}</span>
          <span>
            {log.author.id ? (
              <Link to={generateUserProfileRoute(log.author.id)}>{`${log.author.email} `} </Link>
            ) : (
              `${log.author.email} `
            )}
            <span>{dayjs(log.createdAt).format("YYYY.MM.DD HH:mm")}</span>
          </span>
        </p>
      ))
    }
  ]

  return (
    <div className={cn(style.collapseWrapper, global.scrollbar)}>
      <Collapse ghost onChange={handleCollapse} items={logsItems} expandIconPosition="end" className={style.collapse} />
    </div>
  )
}
