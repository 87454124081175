import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import dayjs, { Dayjs } from "dayjs"
import { VacationDisplayVariant, VacationFilterOption, VacationsScope } from "@enums"
import { DateUnit, initDateNow } from "@helpers"

type VacationsState = {
  date: string
  trackerDate: string
  displayVariant: VacationDisplayVariant
  location: { id?: number; isManageable?: boolean }
  filters: {
    vacation: {
      userId?: string
      status: Array<string>
      legend: Array<string>
      startDate: string
      finishDate: string
    }
    scope: VacationsScope
  }
}

const takeToday = initDateNow()

const initialState: VacationsState = {
  date: takeToday().format(),
  trackerDate: takeToday().format("YYYY-MM-01"),
  displayVariant: VacationDisplayVariant.List,
  location: { id: undefined, isManageable: undefined },
  filters: {
    vacation: {
      status: [],
      legend: [],
      startDate: takeToday().startOf("year").format(),
      finishDate: takeToday().endOf("year").format()
    },
    scope: VacationsScope.All
  }
}

const vacationsSlice = createSlice({
  name: "vacations",
  initialState,
  reducers: {
    clearState(state) {
      state.date = initialState.date
      state.displayVariant = initialState.displayVariant
      state.filters = initialState.filters
      state.location = initialState.location
      state.trackerDate = initialState.trackerDate
    },
    changeLocation(state, action: PayloadAction<{ id?: number; manageable?: boolean }>) {
      state.location.id = action.payload.id
      state.location.isManageable = action.payload.manageable
    },
    changeTrackerDate(state, action: PayloadAction<Dayjs | Date | string>) {
      state.trackerDate = dayjs(action.payload).format()
    },
    setTrackerToday(state) {
      state.trackerDate = takeToday().format("YYYY-MM-01")
    },
    addTrackerMonth(state) {
      state.trackerDate = dayjs(state.trackerDate).add(1, DateUnit.Month).format()
    },
    decreaseTrackerMonth(state) {
      state.trackerDate = dayjs(state.trackerDate).add(-1, DateUnit.Month).format()
    },
    changeDate(state, action: PayloadAction<Dayjs | Date | string>) {
      state.date = dayjs(action.payload).format()
    },
    setToday(state) {
      state.date = takeToday().format()
    },
    addMonth(state) {
      state.date = dayjs(state.date).add(1, DateUnit.Month).format()
    },
    decreaseMonth(state) {
      state.date = dayjs(state.date).add(-1, DateUnit.Month).format()
    },
    addYear(state) {
      state.date = dayjs(state.date).add(1, DateUnit.Year).format()
    },
    decreaseYear(state) {
      state.date = dayjs(state.date).add(-1, DateUnit.Year).format()
    },
    changeVacationDisplay(state, action: PayloadAction<VacationDisplayVariant>) {
      state.displayVariant = action.payload
    },
    changeVacationFilter(state, action: PayloadAction<{ name: VacationFilterOption; value: string }>) {
      const { name, value } = action.payload
      state.filters.vacation[name].includes(value)
        ? (state.filters.vacation[name] = state.filters.vacation[name].filter(filerValue => filerValue !== value))
        : state.filters.vacation[name].push(value)
    },
    changeVacationsScope(state, action: PayloadAction<VacationsScope>) {
      if (!state.filters.vacation.userId) state.filters.vacation.userId = undefined // This parameter, under certain conditions, is a query parameter, so it should not be allowed to be set as empty string in this action
      state.filters.scope = action.payload
    },
    setNextMonth(state) {
      const today = takeToday().format()
      state.filters.vacation.startDate = dayjs(today).add(1, DateUnit.Month).startOf("month").format()
      state.filters.vacation.finishDate = dayjs(today).add(1, DateUnit.Month).endOf("month").format()
    },
    setThisMonth(state) {
      const today = takeToday().format()
      state.filters.vacation.startDate = dayjs(today).startOf("month").format()
      state.filters.vacation.finishDate = dayjs(today).endOf("month").format()
    },
    setPreviousMonth(state) {
      const today = takeToday().format()
      state.filters.vacation.startDate = dayjs(today).add(-1, DateUnit.Month).startOf("month").format()
      state.filters.vacation.finishDate = dayjs(today).add(-1, DateUnit.Month).endOf("month").format()
    },
    setThisYear(state) {
      const today = takeToday().format()
      state.filters.vacation.startDate = dayjs(today).startOf("year").format()
      state.filters.vacation.finishDate = dayjs(today).endOf("year").format()
    },
    setYear(state, action: PayloadAction<{ year: string }>) {
      state.filters.vacation.startDate = dayjs(`${action.payload.year}-01-01`).startOf("year").format()
      state.filters.vacation.finishDate = dayjs(`${action.payload.year}-12-31`).endOf("year").format()
    },
    changeDateRange(state, action: PayloadAction<{ startDate: string; finishDate: string }>) {
      state.filters.vacation.startDate = action.payload.startDate
      state.filters.vacation.finishDate = action.payload.finishDate
    },
    setDefaultDateRange(state) {
      state.filters.vacation.startDate = initialState.filters.vacation.startDate
      state.filters.vacation.finishDate = initialState.filters.vacation.finishDate
    },
    searchUser(state, action: PayloadAction<{ userId: string }>) {
      state.filters.vacation.userId = action.payload.userId
    }
  }
})

export const vacationsActions = vacationsSlice.actions

export default vacationsSlice.reducer
