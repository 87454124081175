import backendApi from "../../backendApi"

import { deleteLeaveType } from "./DELETE/deleteLeaveType"
import { fetchLeaveTypes } from "./GET/fetchLeaveTypes"
import { fetchUnremovableLeaveTypes } from "./GET/fetchUnremovableleaveTypes"
import { fetchVacationSettings } from "./GET/fetchVacationSettings"
import { fetchVacationSettingsOptions } from "./GET/fetchVacationSettingsOptions"
import { createNewLeaveType } from "./POST/createNewLeaveType"
import { createVacationSettings } from "./POST/createVacationSettings"
import { changeVacationSettings } from "./PUT/changeVacationSettings"
import { updateLeaveType } from "./PUT/updateLeaveType"

export const vacationsSettingsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchVacationSettings: fetchVacationSettings(build),
    fetchVacationSettingsOptions: fetchVacationSettingsOptions(build),
    createVacationSettings: createVacationSettings(build),
    changeVacationSettings: changeVacationSettings(build),
    fetchLeaveTypes: fetchLeaveTypes(build),
    updateLeaveType: updateLeaveType(build),
    createNewLeaveType: createNewLeaveType(build),
    deleteLeaveType: deleteLeaveType(build),
    fetchUnremovableLeaveTypes: fetchUnremovableLeaveTypes(build)
  })
})
