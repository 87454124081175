import { SlackUser } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendSearchUsersResponse } from "@state/types/slackUsers"

import { generateSearchSlackUsersUrl } from "../urls"
import { transformSlackUsers } from "../transformer"

export const searchSlackUsers = (build: Build) =>
  build.query<Array<SlackUser>, string>({
    query: search => ({ url: generateSearchSlackUsersUrl(search) }),
    transformResponse: (data: BackendSearchUsersResponse) => transformSlackUsers(data),
    providesTags: ["SlackUser"]
  })
