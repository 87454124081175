import { useMemo, useRef, useState } from "react"
import { DepartmentCard, JobsFormData, UpdateDeptBodyReq } from "@interfaces"
import { Form } from "antd"
import { capitalizeFirstLetter, defaultError, generateShortName } from "@helpers"
import { useAlert } from "@hooks"
import { useDeptCardUtility } from "@app/sections/Departments/helpers/useDeptCardUtility"
import { buildUpdateBodyRequest } from "@app/sections/Departments/helpers/formHelpers"
import { departmentsApi } from "@state/services/subApis"

import { checkIfUpdateRequestContainsAnyChanges, emptyPosition } from "./jobFormHelpers"

export const useJobFormViewState = (
  department: DepartmentCard,
  headProducts: Array<string>,
  toggleEditMode: () => void
) => {
  const { error } = useAlert()

  const { usersWithoutPosition } = useDeptCardUtility()

  const [form] = Form.useForm()

  const updateBody = useRef<UpdateDeptBodyReq>()

  const [updateDepartment] = departmentsApi.endpoints.updateDepartment.useMutation()

  const [isPopconfirmVisible, setIsPopcomfirmVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const userProductsMapping = useMemo(() => {
    const mapping: Record<string, Array<string>> = {}
    department.positions.forEach(position => {
      if (position.userId && position.userName) {
        mapping[position.userId] = position.userProducts
      }
    })
    usersWithoutPosition.forEach(user => {
      mapping[user.value] = user.products
    })
    return mapping
  }, [department.positions, usersWithoutPosition])

  const headProductsMapping = useMemo(
    () => headProducts?.reduce<Record<string, boolean>>((acc, val) => ({ ...acc, [val]: true }), {}),

    [headProducts]
  )

  const deriveUserOptions = (userId?: string) => {
    if (!userId || !department.positions) return usersWithoutPosition

    const userIds = usersWithoutPosition.map(pos => pos.value)
    if (userIds.includes(userId)) return usersWithoutPosition

    const currentUserPosition = department.positions.find(pos => pos.userId === userId)
    const currentUserOption = {
      label: generateShortName(currentUserPosition?.userName?.lastName, currentUserPosition?.userName?.lastName),
      value: userId
    }

    return [currentUserOption, ...usersWithoutPosition]
  }

  const handleDelete = (fieldName: number, remove: (index: number | Array<number>) => void) => {
    const userId = form.getFieldValue(["positionsList", fieldName, "userId"])
    const currentJobs = form.getFieldsValue()
    if (userId) {
      form.setFields([
        {
          name: ["positionsList", fieldName, "userId"],
          errors: ["should be blank"]
        }
      ])
      return
    }
    if (currentJobs.positionsList.length === 1) {
      remove(fieldName)
      form.setFieldsValue({ positionsList: [emptyPosition] })
      return
    }
    remove(fieldName)
  }

  const handleFormSubmit = async (formData: JobsFormData) => {
    const bodyReq = buildUpdateBodyRequest(formData, department)
    updateBody.current = bodyReq

    if (!checkIfUpdateRequestContainsAnyChanges(bodyReq)) {
      toggleEditMode()
      return
    }

    const warningIndex = [...Array(formData.positionsList.length).keys()].find(
      index => form.getFieldWarning(["positionsList", index, "userId"]).length > 0
    )

    if (warningIndex !== undefined) {
      setIsPopcomfirmVisible(true)
    } else {
      await saveDepartment()
    }
  }

  const handlePopconfirmOk = async () => {
    setIsProcessing(true)
    await saveDepartment()
    setIsProcessing(false)
    setIsPopcomfirmVisible(false)
  }

  const handlePopconfirmCancel = () => {
    setIsPopcomfirmVisible(false)
  }

  const saveDepartment = async () => {
    if (!updateBody.current) return

    if (!checkIfUpdateRequestContainsAnyChanges(updateBody.current)) {
      toggleEditMode()
      return
    }

    try {
      await updateDepartment(updateBody.current).unwrap()
      toggleEditMode()
    } catch (e) {
      error(defaultError)
    }
  }

  const checkForDuplicates = (value: string) => {
    const formValues: JobsFormData = form.getFieldsValue()
    const selectedUserIds = formValues.positionsList.filter(item => !!item?.userId).map(item => item.userId)
    const duplicatedIds = selectedUserIds.filter((value, index) => selectedUserIds.indexOf(value) !== index)

    return duplicatedIds.includes(value)
  }

  const uniqueUserValidator = (_: unknown, value: string) => {
    const isDuplicate = checkForDuplicates(value)
    if (isDuplicate) return Promise.reject(new Error("Selected for several job titles"))
    return Promise.resolve()
  }

  const deriveProductsValidator = (firstDepartmentWithOccupiedHeadPosition: string) => (_: unknown, value: string) => {
    if (!value) return Promise.resolve()

    const userProducts = userProductsMapping[value]
    if (!userProducts) return Promise.resolve()

    const newProducts = userProducts.filter(product => !headProductsMapping[product])
    if (newProducts.length === 0) return Promise.resolve()

    const productsText = `product${newProducts.length > 1 ? "s" : ""} ${newProducts
      .map(product => capitalizeFirstLetter(product))
      .join(", ")}`
    const message = `The head of the department ${firstDepartmentWithOccupiedHeadPosition} is currently not associated with\
         the ${productsText}. If you add this employee to the department, the ${productsText}\
         will be assigned to the department head and all heads above in the hierarchy`
    return Promise.reject(new Error(message))
  }

  return {
    form,
    onFormSubmit: handleFormSubmit,
    onPopconfirmOk: handlePopconfirmOk,
    onPopconfirmCancel: handlePopconfirmCancel,
    onDelete: handleDelete,
    isPopconfirmVisible,
    isProcessing,
    deriveUserOptions,
    uniqueUserValidator,
    deriveProductsValidator
  }
}
