import { DepartmentStatus } from "@enums"
import {
  DepartmentJob,
  PositionForDeptCard,
  DepartmentCard,
  DepartmentInfoFormData,
  UpdateDeptBodyReq,
  CreateDeptBodyReq,
  UpdateDeptPositions,
  JobsFormData,
  UpdateSubordinateChiefPosition
} from "@interfaces"
import { differenceWith } from "ramda"

export const deriveFormFieldName = (isEditMode: boolean, name: string) => (isEditMode ? name : undefined)

export const commisionTip = "Activate if the position involves receiving commissiona"

const compareByPosId = (oldPos: PositionForDeptCard, newPos: DepartmentJob) => oldPos.positionId === newPos.position?.id

const compareOldPositions = (
  oldPositions: Array<PositionForDeptCard>,
  newPositions: Array<DepartmentJob>,
  comparer: (oldPos: PositionForDeptCard, newPos: DepartmentJob) => boolean
) => differenceWith(comparer, oldPositions, newPositions)

const createMapForOldPositions = (oldPositions: Array<PositionForDeptCard>) => {
  const map: { [k: string]: PositionForDeptCard } = {}

  oldPositions.forEach(oldPos => {
    map[oldPos.positionId] = oldPos
  })

  return map
}

const deriveClosedAt = (oldStatus: DepartmentStatus, newStatus: DepartmentStatus) => {
  if (oldStatus === newStatus) return undefined

  return newStatus === DepartmentStatus.Inactive ? new Date().toString() : null
}

export const buildUpdateDeptInfoReq = (
  department: DepartmentCard,
  formData: DepartmentInfoFormData,
  isCEO?: boolean
): UpdateDeptBodyReq => ({
  department: {
    id: Number(department.id),
    name: formData.name,
    parent_id: isCEO ? undefined : Number(formData.parentDept),
    start_date: isCEO ? undefined : formData.startDate.toString(),
    closed_at: deriveClosedAt(department.status, formData.status),
    description: formData.description
  },
  chief_position: {
    user_id: formData.chief ? Number(formData.chief) : null
  },
  positions: []
})

const buildUpdatedPositionsForReq = (newPositions: Array<DepartmentJob>, oldPositions: Array<PositionForDeptCard>) => {
  const oldPositionsMap = createMapForOldPositions(oldPositions)
  const deletedPositions = compareOldPositions(oldPositions, newPositions, compareByPosId)
  const relationships: Array<UpdateDeptPositions> = []

  newPositions.forEach(newPos => {
    if (!newPos.position.name && !newPos.userId) return

    if (!newPos.position.id) {
      relationships.push({
        method: "create" as const,
        name: newPos.position.name,
        user_id: Number(newPos.userId) ?? null,
        commission: newPos.position.hasCommission
      })

      return
    }

    const currentOldPosition = oldPositionsMap[newPos.position.id]

    const newPosUserId = newPos.userId ?? null

    if (
      newPosUserId !== currentOldPosition.userId ||
      newPos.position.name !== currentOldPosition.positionName ||
      newPos.position.hasCommission !== currentOldPosition.hasCommission
    ) {
      relationships.push({
        id: Number(newPos.position.id),
        method: "update" as const,
        user_id: newPosUserId !== currentOldPosition.userId ? (newPosUserId ? Number(newPosUserId) : null) : undefined,
        name: newPos.position.name !== currentOldPosition.positionName ? newPos.position.name : undefined,
        commission:
          newPos.position.hasCommission !== currentOldPosition.hasCommission ? newPos.position.hasCommission : undefined
      })
    }
  })

  if (deletedPositions.length) {
    deletedPositions.forEach(deletedPos => {
      if (deletedPos.userId) {
        relationships.push({
          id: Number(deletedPos.positionId),
          method: "update" as const,
          user_id: null
        })
      }
      relationships.push({
        id: Number(deletedPos.positionId),
        method: "destroy" as const
      })
    })
  }

  return relationships
}

export const buildUpdateBodyRequest = (formData: JobsFormData, department: DepartmentCard): UpdateDeptBodyReq => {
  const relationships = buildUpdatedPositionsForReq(formData.positionsList, department.positions)

  const chiefPosition =
    formData.chiefPosition.hasCommission === department.chiefPosition.hasCommission &&
    formData.chiefPosition.name === department.chiefPosition.name
      ? undefined
      : {
          user_id: department.chief?.chiefId ? Number(department.chief?.chiefId) : null,
          commission:
            formData.chiefPosition.hasCommission !== department.chiefPosition.hasCommission
              ? formData.chiefPosition.hasCommission
              : undefined,
          name: formData.chiefPosition.name !== department.chiefPosition.name ? formData.chiefPosition.name : undefined
        }

  const subordinateChiefPositions: Array<UpdateSubordinateChiefPosition> = []
  formData.subordinateChiefs.forEach((chief, index) => {
    if (
      chief.position.name === department.subordinateChiefs[index].positionName &&
      chief.position.hasCommission === department.subordinateChiefs[index].hasCommission
    ) {
      return
    }

    subordinateChiefPositions.push({
      id: Number(chief.position.id),
      commission:
        chief.position.hasCommission !== department.subordinateChiefs[index].hasCommission
          ? chief.position.hasCommission
          : undefined,
      name: chief.position.name !== department.subordinateChiefs[index].positionName ? chief.position.name : undefined
    })
  })

  return {
    department: {
      id: Number(department.id)
    },
    chief_position: chiefPosition,
    positions: relationships.length > 0 ? relationships : undefined,
    subordinate_chief_positions: subordinateChiefPositions.length > 0 ? subordinateChiefPositions : undefined
  }
}

export const buildCreateBodyRequest = (deptInfo: DepartmentInfoFormData): CreateDeptBodyReq => ({
  department: {
    name: deptInfo.name,
    closed_at: deptInfo.status === DepartmentStatus.Active ? null : new Date(),
    start_date: deptInfo.startDate,
    parent_id: deptInfo.parentDept,
    chief_user_id: deptInfo.chief ? deptInfo.chief : undefined,
    description: deptInfo.description
  }
})
