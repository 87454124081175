import React, { FC, useState } from "react"
import { LocationCardSettings } from "@interfaces"
import { Button, Form, Typography } from "antd"
import { Dayjs } from "dayjs"

import { useQuotasEdit } from "../helpers/useQuotasEdit"
import { useSettingsEdit } from "../helpers/useSettingsEdit"
import { separateQuotaFormFields } from "../helpers/separateQuotaFormFields"
import LocationFormFields from "../LocationFormFields"

const EditLocationForm: FC<{ quotaRow: LocationCardSettings }> = ({ quotaRow }) => {
  const { setting, location, quotas } = quotaRow
  const quotasEdit = useQuotasEdit()
  const settingEdit = useSettingsEdit()

  const [isFieldsChanged, setIsFieldsChanged] = useState(false)

  const handleFieldsChange = () => {
    setIsFieldsChanged(true)
  }

  const quotasFormFinish = (fields: { [n: string]: string | Array<string> | Dayjs | Array<Dayjs> | number }) => {
    const { newQuotas, newSettings } = separateQuotaFormFields(fields)

    settingEdit(setting, newSettings)

    quotasEdit(
      newQuotas.filter(([id, value]) => {
        const currentQuota = quotas.find(quota => quota.id === id)
        if (currentQuota?.attributes.days === 0 && value === undefined) return false

        return currentQuota?.attributes.days !== value
      })
    )
  }

  return (
    <Form
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      onFinish={quotasFormFinish}
      onFieldsChange={handleFieldsChange}
    >
      <Form.Item wrapperCol={{ span: 24 }}>
        <Typography.Title style={{ margin: "1rem 0 0 0", textAlign: "center" }} level={4}>
          Change quotas of {location.attributes.name}
        </Typography.Title>
      </Form.Item>

      <LocationFormFields settings={setting.attributes} quotas={quotas} handleFieldsChange={handleFieldsChange} />

      {isFieldsChanged && (
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  )
}

export default EditLocationForm
