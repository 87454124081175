import { GetVacationsRequest } from "@interfaces"
import { Build } from "@state/services/types"
import { GetVacationsResponse } from "@state/types/vacations"
import { VacationsScope } from "@enums"

import { VACATIONS_URL } from "../urls"

export const fetchVacations = (build: Build) =>
  build.query<GetVacationsResponse, GetVacationsRequest>({
    query: ({ scope, status, project_id, startDate, finishDate, user_id, category, includeLeaveType }) => ({
      url: VACATIONS_URL,
      params: {
        "include": includeLeaveType ? "user,quotum.vacation_setting" : "user",
        "extra_fields[vacations]": "balances",
        "extra_fields[users]": "position,department",
        "fields[users]": "id,first_name,last_name,email",
        "fields[quotum]": includeLeaveType ? "" : undefined,
        "fields[quotum.vacation_setting]": includeLeaveType ? "leave_type" : undefined,
        "filter[quotum_id]": category?.length ? category : undefined,
        "filter[user_id]": scope === VacationsScope.All ? user_id : undefined,
        "filter[scope]": scope,
        "filter[status]": status,
        "filter[started_at][lte]": finishDate,
        "filter[finished_at][gte]": startDate,
        "filter[project_id]": project_id?.length ? project_id : undefined
      }
    }),
    providesTags: result => {
      const allVacations =
        (result &&
          result.all.data.length &&
          result.all.data.map(vacation => ({ type: "Vacation", id: vacation.id }))) ||
        []
      const requestsVacations =
        (result &&
          result.requests.data.length &&
          result.requests.data.map(vacation => ({ type: "Vacation", id: vacation.id }))) ||
        []

      const allTags = [
        ...allVacations,
        ...requestsVacations.filter(request => !allVacations.find(vacation => vacation.id === request.id))
      ]

      return allTags.length > 0 ? allTags : ["Vacation"]
    }
  })
