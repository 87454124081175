import { useAlert } from "@hooks"
import { SaveVacationError } from "@interfaces"
import { vacationsQuotasApi } from "@state/services/subApis"

export const useQuotasEdit = () => {
  const { success, error } = useAlert()
  const [changeQuota] = vacationsQuotasApi.endpoints.changeVacationsQuotas.useMutation()

  return (quotasNewData: Array<[id: string, days: number, category: string]>) => {
    quotasNewData.forEach(([id, days, category]) => {
      changeQuota({
        data: {
          id,
          type: "vacation_quota",
          attributes: { days: days === null ? null : days === undefined ? 0 : days }
        }
      }).then(res => {
        if ("data" in res) success(`${category} quota changed successfully`)
        if ("error" in res) {
          const { error: resError } = res as SaveVacationError
          error(resError.data.errors.map(error => error.detail).join("\n"))
        }
      })
    })
  }
}
