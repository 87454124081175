import { VacationQuota } from "@interfaces*"

export const parseQuotas = (quotas: Array<VacationQuota>) => {
  const lastCalculableQuota =
    quotas.reduce((reducer, quota, index) => (quota.attributes.calculable ? index : reducer), 0) + 1

  return {
    calculableQuotas: quotas.slice(0, lastCalculableQuota),
    otherQuotas: quotas.slice(lastCalculableQuota, quotas.length)
  }
}
