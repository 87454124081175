import { DepartmentCard } from "@interfaces*"
import { Col, Flex, Row, Tooltip } from "antd"
import React, { FC } from "react"
import dayjs from "dayjs"
import { Separators, generateDateTemplate, generateFullName } from "@helpers"
import { Link } from "react-router-dom"
import { generateDepartmentRoute, generateUserProfileRoute } from "@routes/inboundRoutes"
import { useDepartmentsAbilities } from "@hooks"

import { Header } from "../../components/Header"

import { EditButton } from "@components/buttons/EditButton"
import { Field } from "@components/Field"

import style from "./style.m.scss"

type DepartmentPreviewProps = {
  department: DepartmentCard
  isCEO: boolean
  toggleEditMode: () => void
}

const DepartmentPreview: FC<DepartmentPreviewProps> = ({ department, isCEO, toggleEditMode }) => {
  const { update: updateDepartment } = useDepartmentsAbilities()

  const handleEditClick = () => {
    toggleEditMode()
  }

  const chiefLink = department.chief ? (
    <Link to={generateUserProfileRoute(department.chief.chiefId)}>
      {generateFullName(department.chief?.lastName, department.chief?.firstName)}
    </Link>
  ) : (
    ""
  )
  const parentLink = department.parentDept ? (
    <Link to={generateDepartmentRoute(department.parentDept.id)}>{department.parentDept.name}</Link>
  ) : (
    ""
  )

  return (
    <Flex vertical>
      <Header>
        {department?.name ?? "Department"}
        <EditButton disabled={!updateDepartment} onClick={handleEditClick} datatest-id="form-edit-button" />
      </Header>

      <Row gutter={24}>
        <Col span={10}>
          <Field title="Department name" value={department.name} />
          <Field
            title="Start date"
            value={dayjs(department?.startDate).format(generateDateTemplate("YYYYMMDD", Separators.BackSlash))}
          />
          <Field title="Status" value={department.status} />
          <Field title={isCEO ? "Employee" : "Head of dept."} value={chiefLink} />
          <Field title="Parent dept." value={parentLink} />
        </Col>
        <Col span={13} className={style.itemContainer}>
          <Field title="Description" value={<div className={style.description}>{department.description ?? "-"}</div>} />
          <Field
            title="Subordinate dept."
            value={
              <div className={style.subdepts}>
                {department.subordinateDepts.length
                  ? department.subordinateDepts.map(subDept => (
                      <Tooltip key={subDept.value} placement="top" title={subDept.label}>
                        <Link to={generateDepartmentRoute(String(subDept.value))}>{subDept.label}</Link>
                        <br />
                      </Tooltip>
                    ))
                  : "No subordinate departments"}
              </div>
            }
          />
        </Col>
      </Row>
    </Flex>
  )
}

export default DepartmentPreview
