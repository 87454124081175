import React, { FC } from "react"
import { Divider } from "antd"
import { VacationQuota, VacationSettingsAttributes } from "@interfaces"

import { parseQuotas } from "../../helpers/parseQuotas"

import style from "./style.m.scss"

type SettingsCardProps = {
  quotas: Array<VacationQuota>
  locationSettings: VacationSettingsAttributes
  onOpenLocations: () => void
}

const deriveQuotaDays = (quota: VacationQuota) => {
  switch (quota.attributes.days) {
    case null:
      return "Unlimited"
    case 0:
      return "Disabled"
    case undefined:
      return "0"
    default:
      return quota.attributes.days
  }
}

const SettingsCard: FC<SettingsCardProps> = ({ quotas, locationSettings, onOpenLocations }) => {
  const { calculableQuotas, otherQuotas } = parseQuotas(quotas)

  return (
    <div className={style.quotas} onClick={onOpenLocations}>
      {calculableQuotas.map(
        (quota, index) =>
          quota && (
            <div key={quota.id} className={style.quotaItem}>
              {!!index && <Divider className={style.settingsDivider} />}
              {quota.attributes.category}:{" "}
              <span className={style.quotaDays}>
                <b>{deriveQuotaDays(quota)}</b>
              </span>
            </div>
          )
      )}

      <div className={style.quotaItem}>
        <Divider className={style.settingsDivider} />
        {"Type of Paid time off: "}
        <span className={style.quotaDays}>
          <b>{locationSettings.expiration ? "Expired" : "Extended"}</b>
        </span>
      </div>

      {!!locationSettings.expiration && (
        <div className={style.quotaItem}>
          <Divider className={style.settingsDivider} />
          {"Expiration date: "}
          <span className={style.quotaDays}>
            <b>{locationSettings.expiration}</b>
          </span>
        </div>
      )}
      {otherQuotas.map(quota => (
        <div key={quota.id} className={style.quotaItem}>
          <Divider className={style.settingsDivider} />
          {quota.attributes.category}:{" "}
          <span className={style.quotaDays}>
            <b>{deriveQuotaDays(quota)}</b>
          </span>
        </div>
      ))}
      <div className={style.quotaItem}>
        <Divider className={style.settingsDivider} />
        {"Type of leaves: "}
        <span className={style.quotaDays}>
          <b>{locationSettings.leave_type}</b>
        </span>
      </div>
      <div className={style.quotaItem}>
        <Divider className={style.settingsDivider} />
        {"Workdays: "}
        <span className={style.quotaDays}>
          <b>{(locationSettings.workdays || []).join(", ")}</b>
        </span>
      </div>
      <div className={style.quotaItem}>
        <Divider className={style.settingsDivider} />
        {"Tooltip for remaining days: "}
        <span className={style.quotaDays}>
          <b>{locationSettings.tooltip || ""}</b>
        </span>
      </div>
    </div>
  )
}

export default SettingsCard
