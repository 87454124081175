import React, { FC } from "react"
import { DepartmentCard } from "@interfaces"
import { Col, Flex, Row } from "antd"
import { CheckOutlined } from "@ant-design/icons"
import { generateShortName } from "@helpers"
import { generateUserProfileRoute } from "@routes/inboundRoutes"
import { Link } from "react-router-dom"
import { useDepartmentsAbilities } from "@hooks"
import cn from "classnames"

import { Header } from "../../components/Header"
import { CommissionInfo } from "../../components/CommissionInfo"
import { ChiefIcon } from "../ChiefIcon"
import jobsStyle from "../style.m.scss"

import { EditButton } from "@components/buttons/EditButton"

import style from "./style.m.scss"

type JobsListProps = {
  department: DepartmentCard
  toggleEditMode: () => void
}

const JobsList: FC<JobsListProps> = ({ department, toggleEditMode }) => {
  const { positions } = department

  const { update: updateDepartment } = useDepartmentsAbilities()

  const subordinateChiefsForView = department.subordinateChiefs.map(chief => ({
    position: {
      id: chief.positionId,
      name: chief.positionName,
      hasCommission: chief.hasCommission,
      department: chief.departmentName
    },
    userName: chief.userId ? generateShortName(chief.userName?.lastName, chief.userName?.lastName) : "",
    userId: chief.userId ?? undefined
  }))

  const defaultJobsForForm = positions.map(job => ({
    position: { id: job.positionId, name: job.positionName, hasCommission: job.hasCommission },
    user: job.userId
      ? { id: job.userId, name: generateShortName(job.userName?.lastName, job.userName?.firstName) }
      : null
  }))

  const handleEditClick = () => {
    toggleEditMode()
  }

  return (
    <div className={style.container}>
      <Header>
        Jobs
        <EditButton disabled={!updateDepartment} onClick={handleEditClick} datatest-id="jobs-edit-button" />
      </Header>

      <div className={jobsStyle.dataTable}>
        <Row gutter={16} className={style.formHeader}>
          <Col span={8}>Job title</Col>
          <Col span={3}>
            <Flex align="center" gap={5}>
              Commission <CommissionInfo />
            </Flex>
          </Col>
          <Col span={8} push={2}>
            Employee
          </Col>
        </Row>
        <Row gutter={16} key={department.chiefPosition.id} className={cn(style.row, jobsStyle.row)}>
          <ChiefIcon departmentName={department.name} className={style.chiefIcon} />
          <Col span={8}>{department.chiefPosition.name}</Col>
          <Col span={3}>
            {department.chiefPosition.hasCommission && <CheckOutlined className={style.checkedIcon} />}
          </Col>
          <Col span={8} push={2}>
            {department.chiefPosition.userId && (
              <Link to={generateUserProfileRoute(department.chiefPosition.userId)}>
                {generateShortName(department.chief?.lastName, department.chief?.lastName)}
              </Link>
            )}
          </Col>
        </Row>
        {subordinateChiefsForView.map(chief => (
          <Row gutter={16} key={chief.position.id} className={cn(style.row, jobsStyle.row)}>
            <ChiefIcon departmentName={chief.position.department} isSubordinateChief className={style.chiefIcon} />
            <Col span={8}>{chief.position.name}</Col>
            <Col span={3}>{chief.position.hasCommission && <CheckOutlined className={style.checkedIcon} />}</Col>
            <Col span={8} push={2}>
              {chief.userId && <Link to={generateUserProfileRoute(chief.userId)}>{chief.userName}</Link>}
            </Col>
          </Row>
        ))}
        {defaultJobsForForm.map(job => (
          <Row gutter={16} key={job.position.id} className={style.row}>
            <Col span={8}>
              <div>{job.position.name}</div>
            </Col>
            <Col span={3}>{job.position.hasCommission && <CheckOutlined className={style.checkedIcon} />}</Col>
            <Col span={8} push={2}>
              {job.user && <Link to={generateUserProfileRoute(job.user.id)}>{job.user.name}</Link>}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  )
}

export default JobsList
