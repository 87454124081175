import { useMemo, useRef, useState } from "react"
import { Form } from "antd"
import { DepartmentCard, DepartmentInfoFormData } from "@interfaces*"
import { DEPARTMENTS_ROUTE } from "@routes/inboundRoutes"
import { useNavigate } from "react-router-dom"
import { capitalizeFirstLetter, defaultError, generateShortName } from "@helpers"
import { DepartmentStatus } from "@enums"
import { useAlert } from "@hooks"
import { buildCreateBodyRequest, buildUpdateDeptInfoReq } from "@app/sections/Departments/helpers/formHelpers"
import { useDeptCardUtility } from "@app/sections/Departments/helpers/useDeptCardUtility"
import { departmentsApi } from "@state/services/subApis"

export const useDepartmentFormViewState = (
  department?: DepartmentCard,
  createMode?: boolean,
  isCEO?: boolean,
  toggleEditMode?: () => void
) => {
  const [updateDepartment] = departmentsApi.endpoints.updateDepartment.useMutation()
  const [createDepartment] = departmentsApi.endpoints.createDepartment.useMutation()
  const { error } = useAlert()
  const navigate = useNavigate()

  const { departmentsForFilters } = departmentsApi.endpoints.fetchDepartmentsOptions.useQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        departmentsForFilters: data?.options || []
      }),
      skip: !createMode
    }
  )

  const { data: departmentProducts } = departmentsApi.endpoints.fetchDepartmentProducts.useQuery(
    department ? department.id : "",
    {
      skip: !department?.id
    }
  )

  const ownedProducts = departmentProducts?.ownedProducts || []

  const [isPopconfirmVisible, setIsPopcomfirmVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const { usersWithoutPosition } = useDeptCardUtility()

  const [form] = Form.useForm()
  const status = Form.useWatch("status", form)
  const name = Form.useWatch("name", form)

  const userOptions = useMemo(() => {
    if (!department?.chief) return usersWithoutPosition
    const currentChiefOption = {
      label: generateShortName(department.chief?.lastName, department.chief?.firstName),
      value: department.chief?.chiefId
    }

    return [currentChiefOption, ...usersWithoutPosition]
  }, [department?.chief, usersWithoutPosition])

  const userProductsMapping = useMemo(() => {
    const mapping: Record<string, Array<string>> = {}
    if (department?.chief) {
      mapping[department.chief.chiefId] = department.chief.products
    }
    usersWithoutPosition.forEach(user => {
      mapping[user.value] = user.products
    })
    return mapping
  }, [department?.chief, usersWithoutPosition])

  const parentDepartmentsOptions = useMemo(() => {
    const allParentDepartmentsOptions = department ? department.availableParents : departmentsForFilters
    return status === DepartmentStatus.Active
      ? allParentDepartmentsOptions.filter(dep => dep.active)
      : allParentDepartmentsOptions
  }, [department, departmentsForFilters, status])

  const updateBody = useRef<ReturnType<typeof buildUpdateDeptInfoReq>>()

  const handleUpdateDeptInfo = async (formData: DepartmentInfoFormData) => {
    if (!department) return

    const bodyRequest = buildUpdateDeptInfoReq(department, formData, isCEO)
    updateBody.current = bodyRequest

    const hasWarning = form.getFieldWarning("chief").length > 0

    if (hasWarning) {
      setIsPopcomfirmVisible(true)
    } else {
      saveDepartment()
    }
  }

  const handleCreateDept = async (formData: DepartmentInfoFormData) => {
    const bodyReq = buildCreateBodyRequest(formData)
    try {
      const newDeptId = await createDepartment(bodyReq).unwrap()
      navigate(`${DEPARTMENTS_ROUTE}/${newDeptId}`)
    } catch (e) {
      error(defaultError)
    }
  }

  const handleFormSubmit = createMode ? handleCreateDept : handleUpdateDeptInfo

  const handlePopconfirmOk = async () => {
    setIsProcessing(true)
    await saveDepartment()
    setIsProcessing(false)
    setIsPopcomfirmVisible(false)
  }

  const handlePopconfirmCancel = () => {
    setIsPopcomfirmVisible(false)
  }

  const saveDepartment = async () => {
    if (!updateBody.current) return

    try {
      await updateDepartment(updateBody.current)
      toggleEditMode?.()
    } catch (e) {
      error(defaultError)
    }
  }

  const productsValidator = (_: unknown, value: string) => {
    const userProducts = userProductsMapping[value]
    if (!userProducts) return Promise.resolve()

    const newProducts = ownedProducts.filter(product => !userProducts.includes(product))
    if (newProducts.length === 0) return Promise.resolve()

    const productsText = `product${newProducts.length > 1 ? "s" : ""} ${newProducts
      .map(product => capitalizeFirstLetter(product))
      .join(", ")}`
    const message = `The employee you are assigning as the head of the department \
    ${name} does not currently have the ${productsText}, which is available to the employees of this department. \
    If you assign this head to the department, the ${productsText} will be added to them`
    return Promise.reject(new Error(message))
  }

  return {
    departmentsForFilters,
    ownedProducts,
    usersWithoutPosition,
    parentDepartmentsOptions,
    form,
    isPopconfirmVisible,
    isProcessing,
    userOptions,
    onFormSubmit: handleFormSubmit,
    onPopconfirmOk: handlePopconfirmOk,
    onPopconfirmCancel: handlePopconfirmCancel,
    productsValidator
  }
}
