import { DepartmentStatus } from "@enums"
import { Department, DepartmentCard, DepartmentJob, DepartmentLog } from "@interfaces"
import {
  BackendDepartmentCardResponse,
  BackendDepartmentLogsResponse,
  BackendDepartmentProducts,
  BackendDepartmentsResponse,
  BackendUpdateDeptResponse,
  IncludedDeptMap,
  IncludedDeptTag,
  IncludedDeptUnion,
  IncludedLogUser,
  IncludedPosition,
  IncludedUser
} from "@state/types/departments"

const createIncludedMap = <T extends IncludedDeptUnion>(
  includeOfUserRoles: Array<IncludedDeptUnion>,
  tag: IncludedDeptTag
): IncludedDeptMap<T> => {
  const map: IncludedDeptMap<T> = {}

  includeOfUserRoles.forEach(entity => {
    if (entity.type === tag) map[entity.id] = entity as T
  })

  return map
}

const splitName = (fullName: string) => fullName.split(" ")

export const transformDepartments = (departments: BackendDepartmentsResponse): Array<Department> => {
  if (!departments.data.length) return []

  const positionsMap = createIncludedMap<IncludedPosition>(departments.included, "positions")
  const usersMap = createIncludedMap<IncludedUser>(departments.included, "users")

  return departments.data.map(department => {
    const { id, attributes } = department
    const chiefPosition = positionsMap[attributes.chief_position_id]
    const chiefUser = chiefPosition.relationships.user.data ? usersMap[chiefPosition.relationships.user.data.id] : null

    const parentDept = attributes.parent ? { ...attributes.parent, id: String(attributes.parent.id) } : null

    return {
      id,
      isCeo: attributes.ceo,
      name: attributes.name,
      allPositionsCount: String(attributes.positions_count),
      occupiedPositionsCount: String(attributes.occupied_positions_count),
      subordinateDepsCount: String(attributes.subordinates_count),
      status: attributes.closed_at ? DepartmentStatus.Inactive : DepartmentStatus.Active,
      parentDept,
      chiefPositionId: chiefPosition.id,
      chief: chiefUser
        ? {
            chiefId: chiefUser.id,
            firstName: chiefUser.attributes.first_name,
            secondName: chiefUser.attributes.second_name,
            lastName: chiefUser.attributes.last_name,
            products: chiefUser.attributes.products
          }
        : null,
      isActivatable: attributes.is_activatable,
      isClosable: attributes.is_closable
    }
  })
}

export const transformDepartment = (department: BackendDepartmentCardResponse): DepartmentCard => {
  const { data, included } = department

  const { attributes, id } = data

  const positionsMap = createIncludedMap<IncludedPosition>(included, "positions")
  const usersMap = createIncludedMap<IncludedUser>(included, "users")

  const chiefPosition = positionsMap[attributes.chief_position_id]
  const chiefUser = chiefPosition.relationships.user.data ? usersMap[chiefPosition.relationships.user.data.id] : null
  const parentDept = attributes.parent ? { ...attributes.parent, id: String(attributes.parent.id) } : null

  const subordinateDepts = data.attributes.subordinates.map(subDept => ({
    value: subDept.id,
    label: subDept.name
  }))

  const parentDepartmentsOptions = data.attributes.available_parents.map(dept => ({
    label: dept.name,
    value: String(dept.id),
    active: dept.active
  }))

  const subordinateChiefs = data.attributes.subordinate_chiefs.map(chief => ({
    positionId: String(chief.id),
    userId: chief.user.id ? String(chief.user.id) : null,
    positionName: chief.name,
    hasCommission: chief.commission,
    departmentName: chief.department.name,
    userName: chief.user.id
      ? {
          firstName: splitName(chief.user.name)[1],
          lastName: splitName(chief.user.name)[0],
          secondName: splitName(chief.user.name)[2]
        }
      : null
  }))

  const positionsWithUserInfo = Object.entries(positionsMap)
    .map(position => {
      const { attributes } = position[1]
      const userId = position[1].attributes.user_id

      const currentUser = usersMap[userId]

      return {
        positionId: position[0],
        userId: userId ?? null,
        positionName: attributes.name,
        hasCommission: attributes.commission,
        userName: currentUser
          ? {
              firstName: currentUser.attributes.first_name,
              lastName: currentUser.attributes.last_name,
              secondName: currentUser.attributes.second_name
            }
          : null,
        userProducts: currentUser?.attributes.products ?? []
      }
    })
    .filter(position => position.positionId !== department.data.attributes.chief_position_id)

  return {
    id,
    isCeo: attributes.ceo,
    name: attributes.name,
    startDate: attributes.start_date,
    status: attributes.closed_at ? DepartmentStatus.Inactive : DepartmentStatus.Active,
    chiefPositionId: chiefPosition.id,
    chief: chiefUser
      ? {
          chiefId: chiefUser.id,
          firstName: chiefUser.attributes.first_name,
          secondName: chiefUser.attributes.second_name,
          lastName: chiefUser.attributes.last_name,
          products: chiefUser.attributes.products || []
        }
      : null,
    parentDept,
    positions: positionsWithUserInfo,
    subordinateDepts,
    subordinateChiefs,
    chiefPosition: {
      id: chiefPosition.id,
      name: chiefPosition.attributes.name,
      hasCommission: chiefPosition.attributes.commission,
      userId: chiefPosition.attributes.user_id
    },
    description: department.data.attributes.description ?? "",
    availableParents: parentDepartmentsOptions,
    isActivatable: attributes.is_activatable,
    isClosable: attributes.is_closable
  }
}

export const transformDepartmentLogs = (response: BackendDepartmentLogsResponse): Array<DepartmentLog> => {
  const usersMap = response.included ? createIncludedMap<IncludedLogUser>(response.included, "users") : {}

  return response.data.map(log => {
    const currentUser = usersMap[log.attributes.user_id]

    return {
      id: log.id,
      message: log.attributes.record,
      timeStamp: log.attributes.created_at,
      user: {
        id: log.attributes.user_id,
        userEmail: currentUser ? currentUser.attributes.email : "system"
      }
    }
  })
}

export const transformDepartmentUpdateResponse = (response: BackendUpdateDeptResponse): Array<DepartmentJob> => {
  if (!response.included) return []

  return response.included.map(pos => ({
    position: {
      id: pos.id,
      name: pos.attributes.name,
      hasCommission: pos.attributes.commission
    },
    userId: pos.attributes.user_id
  }))
}

export const transformDepartmentProductsResponse = (response: BackendDepartmentProducts) => ({
  headProducts: response.head_products,
  ownedProducts: response.owned_products,
  firstDepartmentWithOccupiedHeadPosition: response.nearest_department_with_chief ?? ""
})
