import { VacationLog } from "@interfaces*"
import { BackendVacationLogResponse, IncludedVacationLogUser } from "@state/types/vacations"

const includedMap = (included: Array<IncludedVacationLogUser>) => {
  const users: { [k: string]: { userId: string; email: string } } = {}

  included.forEach(user => {
    users[user.id] = {
      userId: user.id,
      email: user.attributes.email
    }
  })

  return users
}

export const transformVacationLogs = (res: BackendVacationLogResponse): Array<VacationLog> => {
  if (!res.data.length) return []
  const usersMap = includedMap(res.included)

  return res.data.map(log => {
    const { id, attributes, relationships } = log
    const userId = relationships.user.data?.id

    return {
      id,
      record: attributes.record,
      extra: attributes.extra,
      createdAt: attributes.created_at,
      user: userId ? usersMap[userId] : null
    }
  })
}
