import { DepartmentCard, DepartmentProducts } from "@interfaces"
import React, { FC, useEffect } from "react"
import { Button, Checkbox, Col, Flex, Form, Popconfirm, Row, Space } from "antd"
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons"
import { generateShortName } from "@helpers"

import { Header } from "../../components/Header"
import { CommissionInfo } from "../../components/CommissionInfo"
import { ChiefIcon } from "../ChiefIcon"
import jobsStyle from "../style.m.scss"

import { SearchSelect } from "@components/SearchSelect"
import { ValidatedTextInput } from "@components/ValidatedInputs"
import { SaveButton } from "@components/buttons/SaveButton"
import { CancelButton } from "@components/buttons/CancelButton"

import { useJobFormViewState } from "./helpers/useJobFormViewState"
import { deriveInitialFormValues } from "./helpers/jobFormHelpers"
import style from "./style.m.scss"

type JobsFormProps = {
  department: DepartmentCard
  departmentProducts: DepartmentProducts
  toggleEditMode: () => void
}

const formItemLayout = {
  wrapperCol: { span: 24 }
}

const checkboxCellStyle = { display: "flex", justifyContent: "center" }

const JobsForm: FC<JobsFormProps> = ({ department, departmentProducts, toggleEditMode }) => {
  const { headProducts, firstDepartmentWithOccupiedHeadPosition } = departmentProducts

  const {
    form,
    onFormSubmit,
    onPopconfirmCancel,
    onPopconfirmOk,
    isPopconfirmVisible,
    isProcessing,
    deriveUserOptions,
    onDelete,
    uniqueUserValidator,
    deriveProductsValidator
  } = useJobFormViewState(department, headProducts, toggleEditMode)

  const handleCancelClick = () => {
    toggleEditMode()
  }

  useEffect(() => {
    form.validateFields()
  }, [department.positions, headProducts])

  return (
    <Form form={form} {...formItemLayout} onFinish={onFormSubmit} initialValues={deriveInitialFormValues(department)}>
      <Header>
        Jobs
        <Flex>
          <Popconfirm
            title="Are you sure you want to proceed?"
            open={isPopconfirmVisible}
            onConfirm={onPopconfirmOk}
            okButtonProps={{ loading: isProcessing }}
            onCancel={onPopconfirmCancel}
          >
            <SaveButton htmlType="submit" datatest-id="jobs-save-button" />
          </Popconfirm>
          <CancelButton onClick={handleCancelClick} datatest-id="jobs-cancel-button" />
        </Flex>
      </Header>

      <div className={jobsStyle.dataTable}>
        <Form.Item>
          <Row gutter={16}>
            <Col span={8}>Job title</Col>
            <Col span={3}>
              <Flex align="center" gap={5}>
                Commission <CommissionInfo />
              </Flex>
            </Col>
            <Col span={8}>Employee</Col>
          </Row>
        </Form.Item>
        <Row gutter={16} className={jobsStyle.row}>
          <ChiefIcon departmentName={department.name} className={style.chiefIcon} />

          <Col span={8}>
            <Form.Item name={["chiefPosition", "name"]} rules={[{ required: true, message: "can't be blank" }]}>
              <ValidatedTextInput datatest-id="chief-job-title" placeholder="positionName" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={["chiefPosition", "hasCommission"]} valuePropName="checked" style={checkboxCellStyle}>
              <Checkbox datatest-id="chief-commission" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <ValidatedTextInput
                datatest-id="disabled-chief-employee"
                value={department.chief ? generateShortName(department.chief.lastName, department.chief.lastName) : ""}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="subordinateChiefs">
          {fields => (
            <>
              {fields.map(field => (
                <Row gutter={16} key={field.key} className={jobsStyle.row}>
                  <ChiefIcon
                    departmentName={form.getFieldValue(["subordinateChiefs", field.name, "position", "department"])}
                    isSubordinateChief
                    className={style.chiefIcon}
                  />

                  <Col span={8}>
                    <Form.Item
                      name={[field.name, "position", "name"]}
                      rules={[{ required: true, message: "can't be blank" }]}
                    >
                      <ValidatedTextInput datatest-id="sc-job-title" placeholder="positionName" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name={[field.name, "position", "hasCommission"]}
                      valuePropName="checked"
                      style={checkboxCellStyle}
                    >
                      <Checkbox datatest-id="sc-commission" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={[field.name, "userName"]}>
                      <ValidatedTextInput datatest-id="disabled-sc-job-employee" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
        <Form.List name="positionsList">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row gutter={16} key={field.key}>
                  <Col span={8}>
                    <Form.Item
                      name={[field.name, "position", "name"]}
                      rules={[{ required: true, message: "can't be blank", warningOnly: fields.length === 1 }]}
                    >
                      <ValidatedTextInput datatest-id="job-position-name" placeholder="positionName" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name={[field.name, "position", "hasCommission"]}
                      valuePropName="checked"
                      style={checkboxCellStyle}
                    >
                      <Checkbox datatest-id="commission" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      datatest-id="job-position-employee-select-container"
                      name={[field.name, "userId"]}
                      rules={[
                        {
                          validator: uniqueUserValidator
                        },
                        {
                          validator: deriveProductsValidator(firstDepartmentWithOccupiedHeadPosition),
                          warningOnly: true
                        }
                      ]}
                    >
                      <SearchSelect
                        datatest-id="job-position-employee"
                        options={deriveUserOptions(form.getFieldValue(["positionsList", field.name, "userId"]))}
                        placeholder="Employee"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Space>
                      <Button
                        datatest-id="delete-job-button"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          onDelete(field.name, remove)
                        }}
                      />
                      {fields.length - 1 === index && (
                        <Button
                          datatest-id="add-job-button"
                          icon={<UserAddOutlined />}
                          onClick={() => {
                            add()
                          }}
                        />
                      )}
                    </Space>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </div>
    </Form>
  )
}

export default JobsForm
