export const COLORS = {
  ZERO_COLOR: "rgba(255, 255, 255, 0)",
  MAIN_1: "rgb(23, 24, 33)",
  WHITE_1: "rgb(255, 255, 255)",
  BLACK_1: "rgb(0, 0, 0)",
  GRAY_1: "rgb(135, 136, 140)",
  GRAY_DARK: "rgb(80, 80, 80)",
  GRAY_MIDDLE: "rgb(128, 128, 128)",
  GRAY_LIGHT: "rgb(237, 239, 243)",
  GRAY_2: "rgb(191, 193, 196)",
  GRAY_3: "rgb(160, 160, 160)",
  RED_1: "rgb(255, 0, 0)",
  RED_2: "rgb(205, 91, 69)",
  BLUE_1: "rgb(40, 174, 243)",
  BLUE_2: "rgb(33, 34, 45)",
  BLUE_3: "rgb(44, 45, 51)",
  BLUE_4: "rgb(42, 47, 47)",
  BLUE_5: "rgb(120, 162, 183)",
  BLUE_6: "rgb(93, 118, 203)",
  BLUE_7: "rgb(0, 128, 255)",
  OCEAN_1: "rgb(169, 223, 216)",
  TEAL_1: "rgb(102, 205, 170)",
  ORANGE_1: "rgb(254, 185, 90)",
  ORANGE_2: "rgb(73, 67, 56)",
  ORANGE_3: "rgb(187, 139, 84)",
  ORANGE_4: "rgb(215, 157, 65)",
  YELLOW_1: "rgb(244, 196, 48)",
  YELLOW_2: "rgb(200, 208, 45)",
  VIOLET_LIGHT: "rgb(242, 200, 237)",
  VIOLET_3: "rgb(63, 53, 60)",
  VIOLET_2: "rgb(195, 100, 197)",
  VIOLET_1: "rgb(153, 50, 204)",
  GREEN_1: "rgb(80, 200, 120)",
  GREEN_2: "rgb(144, 190, 109)",
  GREEN_3: "rgb(0, 153, 76)"
}

const RGBLength = 4
export const withOpacity = (colorRGB: string, alpha: string) => {
  if (+alpha > 1 || +alpha < 0) {
    throw new Error("Bad alpha")
  }
  return `rgba(${colorRGB.slice(RGBLength, -1)}, ${alpha})`
}
