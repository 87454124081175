export enum VacationsCategoryName {
  paid_time_off = "Paid time-off",
  paid_time_off_half_day = "Paid time-off (Half day)",
  sick_day = "Sick day",
  unpaid_sick_day = "Unpaid sick day",
  working_remotely = "Working remotely",
  unpaid_vacation = "Unpaid vacation"
}

export enum VacationFilterOption {
  legend = "legend",
  status = "status"
}

export enum VacationsScope {
  All = "all",
  My = "my",
  Team = "team",
  Search = "search"
}

export enum ApprovingState {
  approved = "approved",
  review = "on_review",
  rejected = "rejected"
}

export enum VacationCellPosition {
  Start = "Start",
  Middle = "Middle",
  End = "End",
  Single = "Single",
  Hidden = "Hidden"
}

export enum VacationFormFieldName {
  Reason = "reason",
  Legend = "legend",
  Range = "range",
  Date = "date"
}

export enum HolidayFormFieldName {
  HolidayId = "holidayId",
  HolidayName = "holidayName",
  HolidayLocation = "holidayLocation",
  HolidayRange = "holidayRange"
}

export enum VacationDisplayVariant {
  List = "List",
  Tracker = "Tracker",
  Holiday = "Holiday"
}

export enum VacationSolutionVariant {
  Approved = "approved",
  Rejected = "rejected"
}
